import React, { useEffect } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'

import '../../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'

const HoodiePart2Page = () => {
    useEffect(() => {
        const downloadFile = async () => {
            try {
                const response = await fetch('/hoodie_hacking_stuff/its_a_secret_561956123094.zip')
                const blob = await response.blob()

                const url = window.URL.createObjectURL(blob)

                const downloadLink = document.createElement('a')
                downloadLink.href = url
                downloadLink.download = 'its_a_secret.zip'

                document.body.appendChild(downloadLink)
                downloadLink.click()

                document.body.removeChild(downloadLink)
                window.URL.revokeObjectURL(url)
            } catch (error) {
                console.error('Error downloading file:', error)
            }}
        downloadFile()
    }, [])
    return (<Layout languageSwitchLink="/hoodiepart2">
        <SEO title="Hoodie Hacking Part 2"/>
        <Banner class_suffix="deep"/>
        <br />
        <br />
        <p style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto' }}>
            But what is this file that has just been downloaded 👀?
            <br />
            <br />
            <br />
            <i>Note: No need for bruteforcing.</i>
            <br />
            <i>Note2: The password follows the regular expression pattern: </i><code>^[a-zA-Z0-9 ]*$</code><i></i>
            <br />
            <br />
        </p>
    </Layout>)
}
export default HoodiePart2Page
